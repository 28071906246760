<template>
  <div class="w-100 d-flex mb-3 justify-content-center">
    <div class="form-container">
      <div class="header">
        <div class="row">
          <div class="col-12 px-2">
            <p class="form-title dark-gray-text m-0">
              {{$t('message.w9FormHeader')}}
            </p>
            <p class="form-subtitle dark-gray-text">
              {{$t('message.w9SubHeader')}}
            </p>
          </div>
        </div>
      </div>
      <div class="form-body">
        <form class="needs-validation" novalidate ref="form" autocomplete="off">
          <div class="profile-info">
          <!-- Link for original w9 form is removed for now -->
            <!-- <div class="profile-header pb-2" v-if="mandatoryFieldsErrorMessage">
              <p class="m-0 form_link">
                {{$t('message.click')}}
                <span @click="openOriginalForm" class="text-primary link">
                  {{$t('message.here')}}
                </span>
                {{$t('message.profileHeader')}}
              </p>
            </div> -->
            <div class="row">
              <!-- First name -->
              <div class="col-12 col-md-4 col-lg-4 col-xl-4 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.taxFirstName"
                    :placeholder="$tc('message.taxFirstName', 1)"
                    @keyup="validateForm('taxFirstName')"
                    type="text" class="form-control" id="taxFirstName" required>
                  <label class="form-label" for="taxFirstName">
                    {{$tc('message.taxFirstName', 1)}}
                    <span class="asterik">
                      *
                    </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxFirstName', 0)}}
                  </div>
                  <div id="taxFirstName" class="form-text">{{$t('message.hint')}}</div>
                </div>
              </div>
              <!-- Middle name -->
              <div class="col-12 col-md-4 col-lg-4 col-xl-4 p-2">
                <div class="form-floating">
                  <input v-model="formData.taxMiddleName"
                    :placeholder="$t('message.taxMiddleName')"
                     :class="formData.taxMiddleName ? 'was-validated is-valid' : ''"
                    type="text" class="form-control" id="middleName" required>
                  <label class="form-label" for="middleName">
                    {{$t('message.taxMiddleName')}}
                  </label>
                  <div id="middleName" class="form-text">{{$t('message.hint')}}</div>
                </div>
              </div>
              <!-- Last name -->
              <div class="col-12 col-md-4 col-lg-4 col-xl-4 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.taxLastName"
                    :placeholder="$tc('message.taxLastName', 1)"
                    @keyup="validateForm('taxLastName')"
                    type="text" class="form-control" id="taxLastName" required>
                  <label class="form-label" for="taxLastName">{{$tc('message.taxLastName', 1)}}
                    <span class="asterik">
                      *
                    </span>
                  </label>
                  <div class="invalid-feedback">
                     {{$tc('message.taxLastName', 0)}}
                  </div>
                  <div id="taxLastName" class="form-text">{{$t('message.hint')}}</div>
                </div>
              </div>
              <div class="col-12 p-2">
                <div class="form-floating">
                  <input v-model="formData.taxBusinessName"
                  :placeholder="$t('message.taxBusinessName')"
                    :class="formData.taxBusinessName ? 'was-validated is-valid' : ''"
                    type="text" class="form-control" id="businessName">
                  <label class="form-label" for="businessName">{{$t('message.taxBusinessName')}}
                  </label>
                </div>
              </div>
              <!-- Tax classification -->
               <div class="col-12 col-md-6 p-2">
                 <div class="form-floating validate-me">
                    <select
                      class="form-select"
                      v-model="formData.taxClassification"
                      @change="validateForm('taxClassification')"
                      id="taxClassification"
                      required
                      :placeholder="$tc('message.taxClassification', 1)"
                      >
                      <option value="">
                        {{$t('message.selectOne')}}
                      </option>
                      <option v-for="(options, index) in taxClassificationOptions" :key="index" :value="options.value">
                        {{$t(`message.${options.label}`)}}
                      </option>
                    </select>
                    <label for="taxClassification">{{$tc('message.taxClassification', 1)}}
                      <span class="asterik">
                      *
                      </span>
                    </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxClassification', 0)}}
                  </div>
                 </div>
              </div>
              <!-- Tax classification Limited Liability -->
              <div class="col-12 col-md-6 p-2" v-if="formData.taxClassification === 'Limited liability company'">
                 <div class="form-floating validate-me">
                    <select
                      class="form-select"
                      v-model="formData.taxClassificationLimitedLiabilityCompanyClassification"
                      required
                      id="taxClassificationLimitedLiabilityCompanyClassification"
                      @change="validateForm('taxClassificationLimitedLiabilityCompanyClassification')"
                      >
                      <option value="">{{$t('message.selectOne')}}</option>
                      <option v-for="(options, index) in taxClassificationLimitedLiabilityCompanyClassification" :key="index" :value="options.value">
                        {{$t(`message.${options.label}`)}}
                      </option>
                    </select>
                    <label for="taxClassificationLimitedLiabilityCompanyClassification">{{$tc('message.taxClassificationLimitedLiabilityCompanyClassification' ,1)}}
                      <span class="asterik">
                      *
                      </span>
                    </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxClassificationLimitedLiabilityCompanyClassification', 0)}}
                  </div>
                 </div>
              </div>
              <div class="col-12 col-md-6 p-2" v-if="formData.taxClassification === 'Other'">
                <div class="form-floating validate-me">
                  <input
                    id="taxClassificationOtherInstructions"
                    v-model="formData.taxClassificationOtherInstructions"
                    @keyup="validateForm('taxClassificationOtherInstructions')"
                    :placeholder="$tc('message.taxClassificationOtherInstructions', 1)"
                    type="text"
                    class="form-control"
                    required
                    >
                  <label class="form-label" for="taxClassificationOtherInstructions">{{$tc('message.taxClassificationOtherInstructions', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxClassificationOtherInstructions', 0)}}
                  </div>
                </div>
              </div>
              <!-- Exempt code -->
              <div class="col-12 p-0">
                <div class="row m-0 p-0">
                  <div class="col-12 col-md-6 p-2">
                    <div class="form-floating">
                      <input v-model="formData.taxExemptPayeeCode"
                        :class="formData.taxExemptPayeeCode ? 'was-validated is-valid' : ''"
                        :placeholder="$t('message.taxExemptPayeeCode')"
                        type="text" class="form-control" id="taxExemptPayeeCode">
                      <label class="form-label" for="taxExemptPayeeCode">{{$t('message.taxExemptPayeeCode')}}
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 p-2">
                    <div class="form-floating">
                      <input v-model="formData.taxFATCAReportingExemptionCode"
                        :class="formData.taxFATCAReportingExemptionCode ? 'was-validated is-valid' : ''"
                        :placeholder="$t('message.taxFATCAReportingExemptionCode')"
                        type="text" class="form-control" id="taxFATCAReportingExemptionCode">
                      <label class="form-label" for="taxFATCAReportingExemptionCode">{{$t('message.taxFATCAReportingExemptionCode')}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Address -->
              <div class="col-12 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.taxAddress"
                    :placeholder="$tc('message.taxAddress', 1)"
                    required
                    @keyup="validateForm('taxAddress')"
                    type="text" class="form-control" id="taxAddress">
                  <label class="form-label" for="taxAddress">{{$tc('message.taxAddress', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxAddress', 0)}}
                  </div>
                </div>
              </div>
              <!-- City -->
              <div class="col-12 col-md-4 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.taxCity"
                    :placeholder="$tc('message.taxCity', 1)"
                    required
                    @keyup="validateForm('taxCity')"
                    type="text" class="form-control" id="taxCity">
                  <label class="form-label" for="taxCity">{{$tc('message.taxCity', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxCity', 0)}}
                  </div>
                </div>
              </div>
              <!-- State -->
              <div class="col-12 col-md-4 p-2">
                <div class="form-floating validate-me">
                  <select
                      class="form-select"
                      id="taxState"
                      required
                      :placeholder="$tc('message.taxState', 1)"
                      v-model="formData.taxState"
                      @change="validateForm('taxState')"
                      >
                      <option value="">{{$t('message.selectOne')}}</option>
                      <option v-for="(options, index) in usstateList" :key="index" :value="options.abbreviation">
                        {{options.name}}
                      </option>
                    </select>
                  <label class="form-label" for="taxState">{{$tc('message.taxState', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxState', 0)}}
                  </div>
                </div>
              </div>
              <!-- Zipcode -->
              <div class="col-12 col-md-4 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.taxZipcode"
                    :placeholder="$tc('message.taxZipcode', 1)"
                    @keyup="validateForm('taxZipcode')"
                    required
                    type="text" class="form-control" id="taxZipcode">
                  <label class="form-label" for="taxZipcode">{{$tc('message.taxZipcode', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.taxZipcode', 0)}}
                  </div>
                </div>
              </div>
               <div class="col-12 col-md-6 p-2">
                <div class="form-floating">
                  <input v-model="formData.taxRequestersNameAndAddress"
                    :placeholder="$t('message.taxRequestersNameAndAddress')"
                    :class="formData.taxRequestersNameAndAddress ? 'was-validated is-valid' : ''"
                    type="text" class="form-control" id="taxRequestersNameAndAddress" required>
                  <label class="form-label" for="taxRequestersNameAndAddress">
                    {{$t('message.taxRequestersNameAndAddress')}}
                  </label>
                </div>
              </div>
               <div class="col-12 col-md-6 p-2">
                <div class="form-floating">
                  <input v-model="formData.taxAccountNumbers"
                    :placeholder="$t('message.taxAccountNumbers')"
                    :class="formData.taxAccountNumbers ? 'was-validated is-valid' : ''"
                    type="text" class="form-control" id="taxAccountNumbers" required>
                  <label class="form-label" for="taxAccountNumbers">
                    {{$t('message.taxAccountNumbers')}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="tin-info">
            <div class="profile-header py-3">
              <p class="form-part-heading dark-gray-text">
                Part I {{$t('message.tinHeader')}}
              </p>
            </div>
            <div class="row">
              <div class="col-12 p-2">
                <p class="details-para light-gray-text">
                  {{$t('message.tinDetails')}}
                </p>
                <p class="details-para light-gray-text">
                  <span class="font-weight-bold">
                    {{$t('message.note')}}:
                  </span>
                  {{$t('message.taxNote')}}
                </p>
              </div>
              <div class="col-12 col-md-6 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.SSN"
                    placeholder="SIN / SSN / EIN"
                    required
                    @keyup="validateForm('ssn');"
                    v-mask="getMaskForSSNAndEIN"
                    :class="isSSNvalid ? formData.SSN !== '' ? 'is-valid' : '' : 'is-invalid'"
                    type="text" class="form-control" id="ssn">
                  <label class="form-label" for="ssn">
                    {{$tc('message.ssnLabel', formData.taxClassification === 'Individual/sole proprietor or single-member LLC' ? 1 : 0)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.ssn', !isSSNvalid && formData.SSN !== '' ? 0 : 1, {ssn: formData.taxClassification === 'Individual/sole proprietor or single-member LLC' ? 'SSN' : 'EIN'})}}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.confirmSSN"
                    :placeholder="`${$t('message.confirm')} SIN / SSN / EIN`"
                    required
                    v-mask="getMaskForSSNAndEIN"
                    :class="!doesSSNMatches ? 'is-invalid error' : ''"
                    @keyup="validateForm('confirmSSN')"
                    type="text" class="form-control" id="confirmSSN">
                  <label class="form-label" for="confirmSSN">{{$tc('message.confirmSSNlabel', formData.taxClassification === 'Individual/sole proprietor or single-member LLC' ? 1 : 0)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.confirmSIN', doesSSNMatches ? 1 : 0, {ssn: formData.taxClassification === 'Individual/sole proprietor or single-member LLC' ? 'SSN' : 'EIN'})}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="certification-info">
            <div class="profile-header py-3">
              <p class="form-part-heading dark-gray-text">
                Part II {{$t('message.certification')}}
              </p>
            </div>
            <div class="row">
              <div class="col-12 p-2">
                <p class="details-title light-gray-text">
                  {{$t('message.certificationTitle')}}
                </p>
                <ol class="details-para light-gray-text">
                  <li>{{$t('message.certificationSubtitle1')}}</li>
                  <li>{{$t('message.certificationSubtitle2')}}</li>
                  <li>{{$t('message.certificationSubtitle3')}}</li>
                  <li>{{$t('message.certificationSubtitle4')}}</li>
                </ol>
              </div>
               <div class="col-12 p-2">
                <p class="details-title light-gray-text">
                  {{$t('message.certificationInstruction')}}
                </p>
                <p class="details-para light-gray-text">
                  {{$t('message.certificationInstructionDetails')}}
                </p>
              </div>
              <div class="col-12 col-md-6 p-2">
                <div class="form-floating validate-me">
                  <input v-model="formData.taxSignature"
                    :placeholder="$tc('message.signature', 1)"
                    required
                    @keyup="validateForm('taxSignature')"
                    type="text" class="form-control" id="taxSignature">
                  <label class="form-label" for="taxSignature">{{$tc('message.signature', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.signature', 0)}}
                  </div>
                  <div id="taxSignature" class="form-text">{{$t('message.hint')}}</div>
                </div>
              </div>
              <div class="col-12 col-md-6 p-2">
                <div class="form-floating validate-me">
                  <input
                    v-model="formData.taxDate"
                    :placeholder="$tc('message.date', 1)"
                    required
                    @input="validateForm('taxDate')"
                    type="date" class="form-control" id="taxDate"
                    @change="setDateFormat"
                    data-date-format="YYYY-MM-DD" data-date="" >
                  <label class="form-label" for="taxDate">{{$tc('message.date', 1)}}
                    <span class="asterik">
                      *
                      </span>
                  </label>
                  <div class="invalid-feedback">
                    {{$tc('message.date', 0)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="row">
              <div class="col-12 px-2">
                <button class="btn" :disabled="showLoader" :class="showLoader ? 'disableButton' : 'submitButton'" @click.prevent="submitForm" type="submit">{{$t('message.submit')}}
                  <div v-if="showLoader" class="spinner-border spinner-grow-sm spinner-border-sm text-dark mx-1 mr-0" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
                <p class="m-0 pt-2 text-danger" v-if="mandatoryFieldsErrorMessage">
                  {{$t('message.mandatoryFieldsMessage')}}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- <loader v-if="showLoader" /> -->
    </div>
  </div>
</template>
<script>
const einValidator = require('ein-validator')
import W9FormApi from '@/service/api'
import Loader from '@/components/loader.vue'
import moment from 'moment'
import statesList from './US-states.json'
export default {
  components: {
    Loader
  },
  data () {
    return {
      formData: {
        taxFirstName: '',
        taxMiddleName: '',
        taxLastName: null,
        taxBusinessName: '',
        taxClassification: '',
        taxClassificationLimitedLiabilityCompanyClassification: '',
        taxExemptPayeeCode: '',
        taxClassificationOtherInstructions: '',
        taxFATCAReportingExemptionCode: '',
        taxAddress: '',
        taxCity: '',
        taxState: '',
        taxZipcode: '',
        taxRequestersNameAndAddress: '',
        taxAccountNumbers: '',
        SSN: '',
        confirmSSN: '',
        taxSignature: '',
        taxDate: new Date().toISOString().slice(0,10),
        pin: '',
        tosId: 2,
        csrf: ''
      },
      taxClassificationOptions: [
        {
          value: 'Individual/sole proprietor or single-member LLC',
          label: 'taxClassificationOption1'
        },
        {
          value: 'C Corporation',
          label: 'taxClassificationOption2'
        },
        {
          value: 'S Corporation',
          label: 'taxClassificationOption3'
        },
        {
          value: 'Partnership',
          label: 'taxClassificationOption4'
        },
        {
          value: 'Trust/estate',
          label: 'taxClassificationOption5'
        },
        {
          value: 'Limited liability company',
          label: 'taxClassificationOption6'
        },
        {
          value: 'Other',
          label: 'taxClassificationOption7'
        }
      ],
      taxClassificationLimitedLiabilityCompanyClassification: [
        {
          value: 'C',
          label: 'taxClassificationOption2'
        },
        {
          value: 'S',
          label: 'taxClassificationOption3'
        },
        {
          value: 'P',
          label: 'taxClassificationOption4'
        }
      ],
      mandatoryFieldsErrorMessage: false,
      isSSNvalid: true,
      doesSSNMatches: true,
      usstateList: [],
      showLoader: false,
      toastPosition: 'top-right'
    }
  },
  beforeMount () {
    this.usstateList = statesList
     window.onresize = () => {
      this.toastPosition = window.innerWidth < 1064 ? "top"  : "top-right"
    }
    this.getMemberDetails()
  },
  mounted () {
    this.setDateFormat()
  },
  computed: {
    getMaskForSSNAndEIN () {
      return this.formData.taxClassification === 'Individual/sole proprietor or single-member LLC' ? '###-##-####' : '##-#######'
    }
  },
  methods: {
    checkForTaxClassification () {
      if (this.formData.taxClassification === 'Limited liability company') {
        if (this.formData.taxClassificationLimitedLiabilityCompanyClassification === '') return false
      }
      if (this.formData.taxClassification === 'Other') {
        if (this.formData.taxClassificationOtherInstructions === '') return false
      }
      return true
    },
    formatTime (date) {
      if (!date) return moment().format('YYYY-MM-DD')
      return moment(date, ['DD/MM/YY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    },
    async getMemberDetails () {
      let pin = this.$store.getters.pin
      W9FormApi.getMemberDetails(pin)
      .then(response => {
        if (response.data) {
          let x = this.getExternalIdFromMetadataId()
          for (let data of response.data) {
            if (x[data.metadataId]) {
              if (x[data.metadataId] === 'taxDate') {
                this.formData[x[data.metadataId]] = this.formatTime(data.value)
                this.setDateFormat()
                continue
              } else {
                this.formData[x[data.metadataId]] = data.value
              }
              this.validateForm(x[data.metadataId])
            }
          }
        }
      })
      .catch(error => {
        console.log("Auto population failed")
      })
    },
    getExternalIdFromMetadataId () {
      const envKeysAndValues = [
        ['VUE_APP_TAX_FIRST_NAME', 'taxFirstName'],
        ['VUE_APP_TAX_MIDDLE_NAME', 'taxMiddleName'],
        ['VUE_APP_TAX_LAST_NAME', 'taxLastName'],
        ['VUE_APP_TAX_BUSINESS_NAME', 'taxBusinessName'],
        ['VUE_APP_TAX_CLASSIFICATION', 'taxClassification'],
        ['VUE_APP_TAX_CLASSIFICATION_LTD_LIABILITY', 'taxClassificationLimitedLiabilityCompanyClassification'],
        ['VUE_APP_TAX_EXEMPT_PAYEE_CODE', 'taxExemptPayeeCode'],
        ['VUE_APP_TAX_CLASSIFICATION_OTHER_INSTRUCTIONS', 'taxClassificationOtherInstructions'],
        ['VUE_APP_TAX_FATC_REPORTING_EXEMPTION_CODE', 'taxFATCAReportingExemptionCode'],
        ['VUE_APP_TAX_ADDRESS', 'taxAddress'],
        ['VUE_APP_TAX_CITY', 'taxCity'],
        ['VUE_APP_TAX_STATE', 'taxState'],
        ['VUE_APP_TAX_ZIPCODE', 'taxZipcode'],
        ['VUE_APP_TAX_REQUESTERS_NAME_ADDRESS', 'taxRequestersNameAndAddress'],
        ['VUE_APP_ACCOUNT_NUMBER', 'taxAccountNumbers'],
        ['VUE_APP_TAX_DATE', 'taxDate'],
        ['VUE_APP_TAX_SIGNATURE', 'taxSignature']
      ]
      let obj = {}
      for (let records of envKeysAndValues) {
        if (process.env[records[0]]) {
          obj[process.env[records[0]]] = records[1]
        }
      }
      return obj
    },
    setDateFormat () {
      let elem = document.getElementById('taxDate')
      elem.setAttribute('data-date', new Date(this.formData.taxDate).toISOString().substr(0, 10))
      this.validateForm('taxDate')
    },
    openOriginalForm () {
      if (process.env.VUE_APP_ORIGINAL_W9_FORM_URL) {
        parent.window.open(process.env.VUE_APP_ORIGINAL_W9_FORM_URL,"_blank");
      }
    },
    async validateForm (id) {
      // Not required validations
      if (id === null) return

      // Second condition will work when we change taxClassification value. SSN - EIN
      if (id === 'ssn' || (id === 'taxClassification' && this.formData.SSN !== '')) {
        let validateSSN = await this.validateSSN()
        if (!validateSSN) {
          this.isSSNvalid = false
          return
        }
        this.isSSNvalid = true
        if (this.formData.SSN !== this.formData.confirmSSN && this.formData.SSN !== '' && this.formData.SSN !== this.formData.confirmSSN) {
          this.doesSSNMatches = false
        } else {
          this.doesSSNMatches = true
        }
      }
      if (id === 'confirmSSN') {
        if (this.formData.SSN !== this.formData.confirmSSN) {
          this.doesSSNMatches = false
        } else {
          this.doesSSNMatches = true
        }
      }
      this.addedWasValidated(id)
    },

    addedWasValidated (id) {
      console.log("id", id)
      let elems = document.getElementsByClassName("validate-me")
      let data = [...elems]
      if (data) {
        let filterData = data.filter(val => {
          return val.children[0].value === null || val.children[0].value === ''
        })
        if (filterData.length === 0 && this.isSSNvalid && this.doesSSNMatches && this.checkForTaxClassification()) {
          this.mandatoryFieldsErrorMessage = false
        }
      }
      for (let elem of elems) {
        if (elem.children[0].id === id) {
          elem.classList.add('was-validated');
          break
        }
      }
    },
    async validateSSN () {
      if (this.formData.taxClassification === 'Individual/sole proprietor or single-member LLC') {
        return await this.checkForSSN()
      } else {
        return this.checkForEIN()
      }
    },
    async checkForSSN () {
      const regex = '^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$'
      return this.formData.SSN.match(regex) !== null
    },
    checkForEIN () {
      return einValidator.isValid(this.formData.SSN)
    },
    openToast (message, type) {
      let duration = process.env.VUE_APP_TOAST_MESSAGE_DURATION ? process.env.VUE_APP_TOAST_MESSAGE_DURATION : 3000
      this.$toast.open({
        message: message,
        type: type,
        duration: duration,
        position: this.toastPosition
      })
    },
    resetForm () {
      this.formData.taxFirstName = ''
      this.formData.taxMiddleName = ''
      this.formData.taxLastName = ''
      this.formData.taxBusinessName = ''
      this.formData.taxClassification = ''
      this.formData.taxClassificationLimitedLiabilityCompanyClassification = ''
      this.formData.taxExemptPayeeCode = ''
      this.formData.taxClassificationOtherInstructions = ''
      this.formData.taxFATCAReportingExemptionCode = ''
      this.formData.taxAddress = ''
      this.formData.taxCity = ''
      this.formData.taxState = ''
      this.formData.taxZipcode = ''
      this.formData.taxRequestersNameAndAddress = ''
      this.formData.taxAccountNumbers = ''
      this.formData.SSN = ''
      this.formData.confirmSSN = ''
      this.formData.taxSignature = ''
    },
    validateFormFromSubmit () {
      let elems = document.getElementsByClassName("validate-me")
      let data = [...elems]
      if (data) {
        let filterData = data.filter(val => {
          return val.children[0].value === null || val.children[0].value === ''
        })
        for (var elem of elems) {
          elem.classList.add('was-validated');
        }
        return filterData.length === 0 && this.isSSNvalid && this.doesSSNMatches
      }
    },
    async submitForm () {
      if (this.formData.taxClassification === 'Other') {
        this.formData.taxClassificationLimitedLiabilityCompanyClassification = ''
      }
      if (this.formData.taxClassification === 'Limited liability company	') {
        this.formData.taxClassificationOtherInstructions = ''
      }
      if (this.formData.taxAccountNumbers !== null && this.formData.taxAccountNumbers !== undefined && Array.isArray(this.formData.taxAccountNumbers) === false) {
        this.formData.taxAccountNumbers = this.formData.taxAccountNumbers.trim()
      }
      let formIsValid = this.validateFormFromSubmit()
      if (formIsValid) {
        this.mandatoryFieldsErrorMessage = false
        let tosId = this.$store.getters.tosId
        this.formData.tosId = tosId
        this.formData.pin = this.$store.getters.pin
        if (this.doesSSNMatches && this.isSSNvalid) {
          this.showLoader = true
          this.formData.csrf = this.$store.getters.csrf
          W9FormApi.submitFormData(this.formData)
           .then(response => {
             // this.resetForm()
              // Reloading the page
              if (response.data.status) {
                this.openToast(this.$t('message.successMessage'), 'success')
                let message = { status: "success" } // Not able to read from env var because of issues with special chars in bamboo
                let origin = process.env.VUE_APP_MESSAGE_ORIGIN
                if (message && origin) {
                  window.parent.postMessage(message, origin)
                }
              } else {
                this.showLoader = false
                if (response.data.errors) {
                  for (let error of response.data.errors) {
                    let message = error.msg.replace("{FIELD_NAME}", error.metadata.Translations.en.name)
                    this.openToast(message, 'error')
                  }
                } else {
                  this.openToast(this.$t('message.formSubmitFailed'), 'error')
                }
              }
           })
           .catch(error => {
             this.mandatoryFieldsErrorMessage = false
              this.showLoader = false
              const status = error.response.data.error.status
             if (status === 401) {
               this.$router.push({name: 'Unauthorized'})
             } else if (error.response.data.error.status === 403) {
               this.openToast(error.response.data.error.message, 'error')
             } else if (error.response.data.error.status === 412) {
              let entries = Object.entries(error.response.data.error.errors.errors)
              if (entries.length > 0) {
                this.openToast(entries[0][1][0], 'error')
              }
             } else {
               this.$router.push({name: 'ServerError'})
             }
           })
        }
      } else {
        // window.scrollTo(0, 0)
        // window.parent.parent.scrollTo(0,0)
        // to scroll to bottom
        this.mandatoryFieldsErrorMessage = true
        window.scrollTo(0,document.body.scrollHeight + 20);
      }
    }
  }
}
</script>
<style scoped>
input[type="date"] {
    position: relative;
}

input[type="date"]:before {
    position: absolute;
    content: attr(data-date);
    display: inline-block;
}

input[type="date"]::-webkit-datetime-edit, input::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../../assets/images/calendar 1.svg') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
    margin-top: -6px;
}
.form-part-heading {
  font-size: 24px !important;
  font-weight: bold;
  margin: 0;
}
.form-body {
  padding: 16px 25px;
  border: #E5E5E5 solid 1px;
}
.form-container {
  max-width: 1056px;
  width: 100%;
}
label {
  color: #93A0AD;
  width: 100%;
  font-size: 16px !important;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow: hidden;
}
.form-control.is-valid, .was-validated .form-control:valid, .form-select.is-valid, .was-validated .form-select:valid {
  background: #FCFCFC;
  border-color: #00000096;
}
.form-control.is-invalid, .was-validated .form-control:invalid, .form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545 !important;
  background: #ffffff !important;
}
.asterik {
  color: #dc3545;
}
.profile-info, .tin-info {
  width: 100%;
}
.details-para {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.details-title {
  font-size: 18px;
  font-weight: 500;
}
.form-title {
  font-size: 32px;
  font-weight: 700;
}
.form-subtitle {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.button-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.form_link {
  font-size:18px;
  color:#45505B
}
.header {
  padding: 16px 25px;
}
.font-weight-bold {
  font-weight: bold !important;
}
.disabledBtn {
  background: #BDBDBD;
  color: #292929;
  border: none;
  padding: 12px;
}
@media screen and (max-width: 844px) {
  .form-body {
    border:none;
    padding-top: 0;
  }
  .button-container {
    display: none;
  }
  .form-title {
    font-size: 28px;
  }
}

.form-text {
  font-size: 10px !important;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-color);
}
.submitButton {
  background: var(--secondary-bg);
  padding: 12px;
  color: var(--secondary-bg-text);
}
.submitButton:hover {
  background: var(--secondary-bg);
  color: var(--secondary-bg-text);
}
.disableButton {
  background: #BDBDBD;
  padding: 12px;
  color: #292929 !important;
}
</style>